@media only screen and (max-width: 864px) {
  .npt input {
    font-size: 20px;
    line-height: 24px;
  }
  .content__form {
    max-width: 576px;
    width: 100%;
  }
  .npt input {
    padding: 0 80px 12px 0;
  }
  .npt__label {
    font-size: 20px;
    line-height: 24px;
  }
  .check {
    padding-left: 32px;
    padding-bottom: 4px;
  }
  .npt__error {
    font-size: 20px;
    line-height: 24px;
  }
  .npt input {
    padding: 0 80px 12px 0;
  }
  .module-contactomain .content__form .npt + .npt {
    margin-top: 48px;
  }
  .module-contactomain .content__form .npt + .npt {
    margin-top: 48px;
  }
  .content__text .info {
    font-size: 18px;
    line-height: 28px;
  }
  .content__text {
    padding: 24px 6px;
    font-size: 14px;
  }
  .content__text .info__links .sep {
    margin: 0 6px;
    width: 3px;
    height: 3px;
  }
  .logoSpacer svg {
    width: 20vw;
    opacity: 0.5;
  }
  .svgHolder {
    max-width: 20vw;
  }
  .spacerMob {
    height: 10vh;
  }

  .hideDesk {
    display: block;
  }
  .header__burger {
    margin-left: 6px;
  }
  menu .header__box__center {
    display: none;
  }
  .header__box__right .btnborder {
    height: 36px;
    padding: 0 16px;
  }
  .header__box__left {
    width: 100%;
  }
  .header__box__right {
    transform: translate(-6px, -50%);
    position: absolute;
    top: 50%;
    right: 0;
    width: auto;
  }
  .header__box {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .content__info__list {
    width: 100%;
  }
  .content__info__list {
    margin-top: 48px;
  }

  .content__info__list .el {
    font-size: 14px;
    line-height: 14px;
  }
  .content__info__list .el {
    padding: 24px 0;
  }
  .content__info__list .el {
    width: 100%;
  }
  .container-vw {
    padding-left: 24px;
    padding-right: 24px;
  }
  .textBig {
    font-size: 24px;
    line-height: 38px;
  }

  .textBig.smaller {
    font-size: 20px;
    line-height: 30px;
  }

  .module-homeclaim .content__text {
    width: 100%;
    max-width: 898px;
  }
  .titleLine .xs {
    display: block;
  }

  .titleLine .xs .line .line__move {
    font-weight: 400;
    font-style: bold;
  }
  .titleLine .lg {
    display: none;
  }
  .titleLine h2 .line {
    font-size: 46px;
    line-height: 46px;
    letter-spacing: -2.5px;
    font-weight: 500;
    font-style: normal;
  }

  .headImage {
    margin-top: 40px;
  }

  .headImage {
    height: unset;
    overflow: hidden;
    position: relative;
  }
  div img {
    object-fit: contain;
    width: unset;
    object-position: 50% 50%;
    width: calc(100vw - 48px);
  }
  .btnborder span {
    font-size: 14px;
    line-height: 14px;
  }
  .module-homeclaim .content .btnborder {
    min-width: 220px;
  }
  .btnborder {
    height: 50px;
  }
  .module-homeclaim .content__text p {
    margin-bottom: 32px;
  }
  .container-vw .content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .swiper {
    max-height: unset;
    padding-left: 24px;
    padding-right: 24px;
  }
  .swiper-slide .inner img {
    object-fit: cover;
    width: 100%;

    object-position: 50% 50%;
  }

  .reservaForm {
    display: none;
  }
  .module-homeclaim .content__text.cenik > div::after {
    display: none;
  }

  .module-homeclaim .content.cenik {
    display: block;
    width: 100%;
    padding: 24px;
    margin-bottom: 4vw;
  }
  .module-homeclaim .content__text.cenik {
    display: block;
    width: 100%;

    margin-bottom: 4vw;
  }
  .module-homeclaim .content__text.cenik > div p:nth-of-type(1) {
    text-align: left;
  }
  .module-homeclaim .content__text.cenik > div p:nth-of-type(2),
  .module-homeclaim .content__text.cenik > div p:nth-of-type(3) {
    width: max-content !important;
    padding-left: 10px;
  }
  .module-homeclaim .content__text.cenik > div p {
    font-size: 16px;
    line-height: 26px;
  }
  .module-homeclaim .content__text.cenik.small > div p {
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 8px;
  }
  .bgImage img {
    height: unset;
    max-width: unset;
    width: 80vw;
  }

  .module-homeclaim .content__text.cenik > div p.headline {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 30px;

    line-height: 30px;
  }
  .module-homeclaim .content__text.cenik > div p.headline span {
    display: block;
    padding-left: 0;
  }
  .bgImage img.full {
    max-width: 100vw;
    width: 100vw;
    transform-origin: right;
  }

  .header__box__center svg {
    width: 20vw;
  }
  .spacer {
    height: 30vh;
  }
  .sec {
    position: relative;
    height: 50vh;
  }
  .header__box__center {
    display: none;
  }

  .sideBut {
    font-size: 16px;
  }
  .swiper-button-prev {
    margin-left: 0rem;
  }

  .swiper-button-Holder {
    margin-top: 1rem;
    justify-content: space-between;
  }

  .hideMob {
    display: none;
  }
  .content__dualcol p {
    font-size: 18px;
    column-count: 1;
    line-height: 28px;
  }
}

.module-aboutintro .content__dualcol {
  width: 83.3333333333%;
  max-width: 1901.7px;
  margin: 13.89vw auto;
}

@media only screen and (min-width: 2800px) {
  .content__dualcol {
    margin: 388.9px auto;
  }
}

@media only screen and (max-width: 1440px) {
  .content__dualcol {
    margin: 200px auto;
  }
}

@media only screen and (max-width: 1024px) {
  .content__dualcol {
    width: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .content__dualcol {
    margin: 64px auto;
  }
}

.module-aboutintro .content__dualcol p {
  font-family: MillerText;
  line-height: 28px;
  font-size: 1.25vw;
  line-height: 1.94vw;
  break-inside: avoid;
}

@media only screen and (min-width: 2801px) {
  .content__dualcol p {
    font-size: 35px;
    line-height: 54.44px;
  }
}

@media only screen and (max-width: 1440px) {
  .content__dualcol p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 861px) {
  .content__dualcol p {
    column-count: 2;
    column-gap: 1.67vw;
  }
}

@media only screen and (max-width: 860px) {
  .content__dualcol p {
    text-align: left;
  }
}
