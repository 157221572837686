/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: "Morion Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Morion Regular"), url("morion-regular.woff2") format("woff");
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: "Morion Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Morion Bold"), url("morion-bold.woff2") format("woff");
}
