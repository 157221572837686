*,
*::after,
*::before {
  box-sizing: border-box;
  font-smoothing: antialiased;
  -webkit-font-smoothing: -antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
}
:root {
  --bold: "Rowan-Bold";
}
* {
  position: relative;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.field,
p,
a {
  font-family: "Morion Regular";
}
a.btnborder span {
  font-weight: 700 !important;
}
body {
  font-size: 1.25vw;
  min-height: 120vh;
  background-color: black;
}
.container-vw {
  margin: 0 auto;
  width: 100%;
  max-width: 2800px;
  padding-left: 9.17vw;
  padding-right: 9.17vw;
}

.field a {
  pointer-events: all !important;
}
.container-vw.inContent {
  padding-top: 18.06vw;
}
.color-Pekarna {
  --bgColor: #f4dccc;

  --textColor: #39507e;
}
.color-Default {
  --bgColor: #e0e1df;

  --textColor: #3b4f34;
}
.color-okoli {
  --textColor: #aac4a0;
  --bgColor: #54624f;
}
.color-pata {
  --textColor: #aac4a0;
}
.color-ubytko {
  --textColor: #a099b3;
  --bgColor: #2c2934;
}

.color-Pokoje {
  /* --bgColor: #bbb4af;
  --textColor: #5c4646;
  --bgColor: #ffffff;
  --textColor: #5c4646; */
  --bgColor: #e0e1df;

  --textColor: #3b4f34;
}
body {
  background-color: var(--bgColor);
  color: var(--textColor);
}
.headImage {
  margin-top: 2.78vw;
  /* max-height: calc(90vh - 48px); */
  overflow: hidden;
}

.titleLine {
  width: 100%;
  max-width: 2286px;
}
.titleLine h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Noto Serif", serif;
  font-weight: 400;
}

.headImage img {
  object-fit: contain;
  width: 100%;
  object-position: 50% 50%;
  height: 100%;

  width: 100%; /* image box size as % of container, see step 1 */
  height: 100%; /* image box size as % of container, see step 1 */
  object-fit: cover; /* matching of image pixels to image box, see step 2 */
}

.module-homeclaim {
  position: relative;
  padding-top: 15.06vw;
  padding-bottom: 15.06vw;
}
.module-homeclaim.noTop {
  position: relative;
  padding-top: 7.06vw;
}
.noTop {
  position: relative;
  padding-top: 7.06vw !important;
}
.module-homeclaim.noPad {
  padding-top: 6.06vw;
  padding-bottom: 6.06vw;
}

.module-homeclaim .content__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 62.36vw;
}

.module-homeclaim .content__text.cenik {
  display: block;
  width: 100%;
  width: 62.36vw;
  margin-bottom: 4vw;
}

.module-homeclaim .content__text.cenik > div {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.module-homeclaim .content__text.cenik > div::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  transform: translateY(-15px);
  background-color: var(--textColor);
  z-index: -1;
  opacity: 0.2;
}

.module-homeclaim .content__text.cenik > div.noLine::after {
  display: none;
}

.module-homeclaim .content__text.cenik > div.nadpis {
  border: 0;
}

.module-homeclaim .content__text.cenik > div p {
  color: var(--textColor);
  font-size: 24px;
  line-height: 28px;
}

.module-homeclaim .content__text.cenik > div p.headline {
  font-size: 26px;
  margin-top: 35px;
  margin-bottom: 30px;
  font-family: "Noto Serif", serif;
  line-height: 30px;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;

  font-style: normal;
}

.module-homeclaim .content__text.cenik > div p.headline span {
  font-weight: 200;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  padding-left: 25px;
}
.module-homeclaim .content__text.cenik p {
  background-color: var(--bgColor);
  margin-bottom: unset;
}
.module-homeclaim .content__text.cenik > div p:nth-of-type(1) {
  padding-right: 10px;
}
.module-homeclaim .content__text.cenik > div p:nth-of-type(2),
.module-homeclaim .content__text.cenik > div p:nth-of-type(3) {
  text-align: right;
  white-space: nowrap;
  padding-left: 10px;
}

.textBig {
  font-size: 2.48vw;
  letter-spacing: -0.05vw;
  line-height: 3.53vw;
  color: var(--textColor);
  font-family: "Morion Regular";

  font-weight: 200;

  font-style: normal;
}
.textBig.smaller {
  font-size: 2.08vw;
  line-height: 2.83vw;
}

.textBig.smalest {
  font-size: 1.08vw;
  line-height: 1.83vw;
}
.textBig strong {
  font-family: "Morion Bold";
  font-weight: 500;

  font-style: normal;
}

.module-homeclaim .content {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
}
.module-homeclaim .content__text p {
  text-align: center;
  margin-bottom: 2.22vw;
}

.btnborder {
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 3.47vw;
  max-height: 77.8px;
}

.reservaForm {
  width: 100%;
  margin-top: 2.78vw;
  display: flex;
  border-top: 1px solid var(--textColor);
  border-bottom: 1px solid var(--textColor);
}

.reservaForm.noBrder {
  border: 0;
  opacity: 0.5;
  margin-bottom: 2.78vw;
}

.reservaForm.noBrder .field,
.reservaForm.noBrder .field:nth-last-child(1) {
  border: 0;
}
.reservaForm .field {
  width: 100%;
  max-width: calc(100% / 5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-left: 1px solid var(--textColor);
  color: var(--textColor);

  cursor: pointer;
  letter-spacing: -0.6px;
  font-size: 1.1em;
  font-weight: 500;
  overflow: hidden;
  font-style: normal;
}
.field a {
  display: block;
  padding: 24px 0;
  width: 100%;
  text-align: center;
}
.field a:hover {
  cursor: pointer !important;
  display: block;
}

.reservaForm .field:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--textColor);
  opacity: 0.2;
  z-index: -1;
  transform: translateY(100%);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}

.reservaForm .field:nth-last-child(1) {
  border-right: 1px solid var(--textColor);
}
.titleLine {
  width: 100%;
  max-width: 2286px;
}
.titleLine h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleLine .xs {
  display: none;
}
.titleLine h2 .line {
  padding: 8px 0;
}
.titleLine h2 .line {
  font-size: 44px;
  line-height: 44px;
}
.titleLine h2 .line {
  white-space: nowrap;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  overflow: hidden;
  color: var(--textColor);
  padding: 1.39vw 0;
  font-size: 8.5vw;
  font-family: "Noto Serif", serif;
  line-height: 9.5vw;
  letter-spacing: -0.07vw;
}

.titleLine h2 .line span {
  font-family: "Noto Serif", serif;
}
.titleLine h2 .line i {
  font-weight: 100;
}
.titleLine h2.half .line,
.titleLine h2 .line.half {
  font-size: 4.5vw;
  line-height: 4.5vw;
}

.titleLine h2 .line.half {
  text-transform: none;

  letter-spacing: -0.1vw;
  font-weight: 100;

  font-style: italic;
}
.titleLine h2 .line:after,
.titleLine h2 .line:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: var(--textColor);
  height: 1px;
  width: 0%;
  opacity: 0.3;
  transition: all 1.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.titleLine h2 .line.anim:after,
.titleLine h2 .line.anim:before {
  width: 100%;
}
.titleLine h2 .line .line__move,
.titleLine h2 .line .line__move {
  transform: translateY(100%);
  opacity: 0;
  transition-delay: 1.7;
}

.titleLine h2 .line.anim .line__move,
.titleLine h2 .line.anim .line__move {
  scale: 1;
  opacity: 1;
  transform: translateY(00%);

  transition: all 1.2s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 1.7;
}

.titleLine h2 .line:nth-of-type(2)::before,
.titleLine h2 .line:nth-of-type(3)::before,
.titleLine h2 .line:nth-of-type(4)::before,
.titleLine h2 .line:nth-of-type(5)::before {
  display: none;
}
.titleLine h2 .line:nth-of-type(3)::before {
  display: none;
}
.titleLine h2 .line:before {
  top: 0;
}
.titleLine h2 .line:after {
  bottom: 0;
}

main {
  width: 100vw;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  transition: opacity 0.6s cubic-bezier(0.43, 0.39, 0.23, 1.01),
    transform 0.9s cubic-bezier(0.55, 0, 0.1, 1);
}
.btnborder {
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 3.47vw;
  max-height: 77.8px;
  overflow: hidden;
}
.module-homeclaim .content .btnborder {
  min-width: 15.28vw;
}
.btnborder span {
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 1.25vw;
  color: var(--textColor);
  line-height: 1.25vw;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  font-family: "Morion Regular";
  font-family: "Noto Serif", serif;
  font-weight: 400;

  font-style: normal;
}
.btnborder:after,
.btnborder:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.btnborder:before {
  border-radius: 1px;
  border: 2px solid var(--textColor);
}
.btnborder:after {
  background: var(--textColor);
  transform: translateY(100%);
  border-radius: 1px;
  transform-origin: center bottom;
}

.btnborder:hover {
  cursor: pointer;
}

.btnborder:hover:after {
  transform: translateY(0);
  opacity: 0.2;
}

.btnborder:after,
.btnborder:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}

a {
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
.smallSwipe .swiper-slide .inner {
  display: flex;

  flex-direction: row;
  overflow: hidden;
}

.swiper-slide .inner img {
  object-fit: cover;

  object-position: 50% 50%;
}
.ubytovaniSwiper {
  max-width: 100vw;

  padding-bottom: 9.17vw;
  overflow: hidden;
}

.swiper {
  padding-left: 9.17vw;
  padding-right: 9.17vw;
}

.swiper .inner img {
  max-height: 90vh;
  width: 100%;
}

.noise {
  z-index: 9999999998;
  position: fixed;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  pointer-events: none;
  opacity: 0.9;
}

.noise_overlay {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: var(--height);
  opacity: 0.2;

  pointer-events: none;
  top: 0;
  z-index: 999999999;

  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 255 255'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='franctalNoise' baseFrequency='1.2'%3E%3C/feTurbulence%3E%3C/filter%3E%3Crect width='100%25' height='100vh' filter='url(%23noiseFilter)'%3E%3C/rect%3E%3C/svg%3E");
}

.header,
.header__box {
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  width: 100%;
  display: flex;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
}
.scrolled .header__box {
  padding-top: 0;
  padding-bottom: 0;
}
.header__box {
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 106;
}
.header__box__left {
  width: 100%;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.header__box__center {
  opacity: 1;
}

.header__box__center svg {
  width: 8vw;
}

.header__box__center {
  flex-shrink: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
svg:not(:root) {
  overflow: hidden;
}
.header__box__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media (hover: hover) {
  .reservaForm .field:hover:before {
    transform: translateY(0);
  }
}

.curtainsColor {
  width: 100vw;
  bottom: 0;
  scale: 1.5;
  position: fixed;
  top: 0;
  z-index: -1;
}

/* .curtainsColor > div.curtainUbytko {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #2c2934 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.curtainsColor > div.curtainOkoli {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #54624f 0%,
    rgba(0, 0, 0, 0) 100%
  );
} */

.curtainsColor > div {
  width: 100vw;

  opacity: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.curtainsColor > div:nth-of-type(1) {
  z-index: 1;
}

.curtainsColor > div:nth-of-type(2) {
  z-index: 2;
}
.curtainsColor > div:nth-of-type(3) {
  z-index: 3;
}

.animSectio {
  position: relative;
}
.spacer {
  height: 9.17vw;
  position: relative;

  display: flex;

  flex-direction: column;
  justify-content: center;
}

.section {
  position: relative;
}

.sec {
  position: relative;
  height: 0;
}
.bgImage {
  mix-blend-mode: multiply;
  filter: contrast(0.5) brightness(1) saturate(0.8);
  z-index: -1;

  top: 0;
}

.bgImage.rb {
  top: 0;
  transform-origin: right;
  position: absolute;
  right: 0;
}
.bgImage.cc {
  top: 0;
  transform-origin: right;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translateX(-50%) translateY(-50%);
}
.bgImage img {
  max-width: 600px;
  max-width: 20vw;
  opacity: 0.6;
}

.bgImage img.full {
  max-width: 100vw;
  width: 80vw;
  transform-origin: right;
}

.imwrao {
  transform-origin: left;
}

.imwrao.r {
  transform-origin: right;
}

/* background: radial-gradient(
  38.66% 15.89% at 52.17% 100%,
  #917d78 0%,
  rgba(0, 0, 0, 0) 100%
); */
.cols2 {
  display: flex;
  flex-direction: row;
}

.cols2 > div {
  filter: blur(100px);
  flex-basis: 50%;
  padding: 2.92vw;
  display: flex;
  flex-direction: column;
  background-color: red;
  justify-content: center;
}

p {
  margin-top: 2.22vw;
  font-size: 2vw;
  text-align: center;
  line-height: 2.78vw;
}

.cols2 > div img {
  object-fit: contain;
  max-height: 90vh;
}

.module-homedual .content__text__info {
  margin-top: 2.92vw;
  font-size: 0.97vw;
  line-height: 0.97vw;
}

.swiper-button-prev,
.swiper-button-next {
  top: auto;
  bottom: unset;
  width: 6rem;
  height: 6rem;
  top: unset;
  left: unset;
  right: unset;

  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  position: relative;
  border-radius: 50vw;
  border: 1px solid var(--textColor);
}

#Fill-1 {
  scale: 0.8;
  transform-origin: center center;
  fill: var(--textColor);
}

#logo path,
#logo ellipse {
  fill: var(--textColor);
}

#swiperButtonHeight {
  opacity: 0;
}
.swiper-button-Holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
  height: 10rem;
  margin-top: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
.swiper.fadeSlideActivity .swiper-button-Holder {
  transform: translateY(-15rem);
  z-index: 500;
  width: 100%;
}
.swiper-button-Holder.center {
  justify-content: center;
}

.iframeWrap iframe {
  overflow-y: hidden;
}

.swiper-pagination-progressbar {
  margin: 0 auto;
  background: #fff9;
  margin-top: 5rem;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}
.swiper-button-prev {
  transform: rotate(180deg);
  margin-left: 3rem;
  margin-right: 1rem;
}
.swiper-button-next {
  margin-left: 1rem;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
.swiper-button-Holder {
  justify-content: space-between;
}

.ubytovaniSwiper:hover {
  cursor: grab;
}

/* .swiper3 .swiperContent::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  opacity: 0.3;
  border-radius: 50vw 50vw 0 0;
  border-radius: 50px;
  mix-blend-mode: color-burn;
} */

.swiper3 .swiperContent .inner {
  border-radius: 50vw;
  border-radius: 50vw 50vw 0 0;
  border-radius: 20px;
  overflow: hidden;
}

.content__dualcol {
  width: 83.3333333333%;
  max-width: 1901.7px;
  margin: 13.89vw auto;
}
.module-aboutintro .content__dualcol p {
  line-height: 28px;
  font-size: 1.25vw;
  line-height: 1.94vw;
  break-inside: avoid;
}
.content__dualcol p {
  line-height: 28px;
  font-size: 1.25vw;
  line-height: 1.94vw;
  break-inside: avoid;
  text-align: left;
}
.content__dualcol p {
  column-count: 2;
  column-gap: 1.67vw;
}
.textMed {
  font-size: 2vw;
  line-height: 2.3vw;
}

.textMed {
  color: var(--textColor);
  font-size: 2.22vw;
  line-height: 2.78vw;
}

@media only screen and (min-width: 2801px) {
  .textMed {
    font-size: 62.22px;
    line-height: 77.78px;
  }
}

@media only screen and (max-width: 1080px) {
  .textMed {
    font-size: 24px;
    line-height: 32px;
  }
}

.titleBig {
  text-transform: uppercase;
  position: relative;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  text-align: center;
  font-size: 5.35vw;
  line-height: 5.35vw;
}

.titleBig span.small {
  font-size: 1.35vw;
  line-height: 1.35vw;
  text-transform: none;
  font-family: "Morion Regular";
}
@media only screen and (min-width: 2801px) {
  .titleBig {
    font-size: 149.72px;
    line-height: 149.72px;
  }
}

@media only screen and (max-width: 748px) {
  .titleBig {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 460px) {
  .titleBig {
    font-size: 9vw;
  }
}

.center {
  text-align: center;
  justify-content: center !important;
}
.content__info__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  width: 83.3333333333%;
  margin: 3.33vw auto 0;
}
.content__info__list .el {
  font-size: 0.97vw;
  line-height: 0.97vw;
  text-transform: uppercase;
  padding: 1.67vw 0;
  width: 40%;
  position: relative;
}
.content__info__list .el:before {
  content: "";
  background: var(--textColor);
  height: 1px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #ffffff;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: unset;
  height: unset;
  border-radius: 0vw;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: none;
  background: none;
}

.logoSpacer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3.78vw;
}
.logoSpacer svg {
  width: 5vw;
  opacity: 0.5;
}

menu .menuHold {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bgColor);
  color: var(--textColor);
  z-index: 1000;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 0;
  pointer-events: none;
}

menu.show .menuHold {
  pointer-events: all;
  opacity: 1;
}

.menuHold a {
  color: var(--textColor);
}

.menuHold .meuInner {
  margin: 0 auto;
  width: 100%;
  height: 100svh;
  padding: 9.17vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuHold .meuInner a {
  display: block;
  font-weight: 300;
  font-family: "Noto Serif", serif;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transform-origin: left bottom;
}
.menuHold .meuInner a:hover {
  transform: skew(-10deg);
}
.menuHold .meuInner a.current {
  transform: skew(-10deg);
  font-weight: 500;
}

.header__box.second {
  position: fixed;
  top: 0;
  opacity: 0;
  z-index: 10001;
  margin-top: -100%;
  background-color: var(--bgColor);
}
body.is-scrolled .header__box.second,
body.active-menu .header__box.second {
  opacity: 1;
  margin-top: 0%;
}

body.active-menu .header__box.second {
  border-bottom: 0px solid var(--textColor);
}
.header__box.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

menu.show .header__box.hidden {
  transform: translateY(0%);
}

.close {
  position: absolute;
  top: 9.17vw;
  right: 9.17vw;
}

body.is-scrolled main .header__box {
  opacity: 0;
}
.header__burger {
  margin-left: 12px;
  width: 72px;
  height: 72px;
  position: relative;
  transition: all 0.9s cubic-bezier(0.55, 0, 0.1, 1);
}
.header__burger .static {
  width: 40px;
  height: 18px;
  position: absolute;
  left: 16px;
  top: 26px;
  pointer-events: none;
}
.header__burger .open {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.header__burger .open:before,
.header__burger .open span {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: var(--textColor);
}
.header__burger .open:before {
  content: "";
  top: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.header__burger .open:before,
.header__burger .open span {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: var(--textColor);
}
.header__burger .open:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  background-color: var(--textColor);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.header__burger .open span {
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1) 0.6s,
    background-color 0.6s cubic-bezier(0.55, 0, 0.1, 1) 0s;
}
.active-menu .header__burger .open:before {
  top: 50%;
  transform: rotate(45deg);
}
.header__burger .open:before {
  content: "";
  top: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.active-menu .header__burger .open:before {
  top: 50%;
  transform: rotate(45deg);
}
.header__burger .open:before {
  content: "";
  top: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.header__burger .open:before,
.header__burger .open span {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: var(--textColor);
}

.active-menu .header__burger .open span {
  transform: translateY(-50%) scaleX(0);
  transition-delay: 0s;
}
.active-menu .header__burger .open:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

.sideBut {
  text-transform: uppercase;
  font-size: 1.2vw;
}
.sideBut a {
  font-family: "Noto Serif", serif;
  font-weight: 400;
}
.header__burger {
  cursor: pointer;
}
.curHolder {
  position: fixed !important;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10002 !important;
  left: 0;
  z-index: 3000;
  pointer-events: none;
}
body .curtains {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;

  background-color: #54624f;
  filter: brightness(0.958);
  z-index: 10002;
  transform: translateY(0%);
  transition: all 0.9s cubic-bezier(0.55, 0, 0.1, 1);
}
body.loaded .curtains {
  transform: translateY(-100%);
  transition: all 0.9s cubic-bezier(0.55, 0, 0.1, 1);
}

p span.bol {
  font-weight: bold;
  font-family: "Morion Bold";
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  width: 100%;
}
.header__box__right .btnborder:before {
  border: 2px solid transparent;
  border-radius: 1px;
}

.header__box__right .btnborder {
  background-color: var(--textColor);
}
.header__box__right .btnborder a span {
  color: var(--bgColor);
  z-index: 5000;
}

.hideDesk {
  display: none;
}
.spacerMob {
  height: 30vh;
}
p.left {
  text-align: left;
}

.upper {
  text-transform: uppercase;
  scale: 0.7;
  display: block;
}

.svgHolder {
  padding: 3vw 0 3vw 0;
  max-width: 160px;
  margin: 0 auto;
}
.svgHolder.nopad {
  padding: 0;
}
.content__text .info {
  font-family: "Morion Bold";
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  transition: all 1.2s cubic-bezier(0.55, 0, 0.1, 1) 0.6s;
  font-size: 1.25vw;
  line-height: 1.67vw;
}
.content__text .info__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.module-contactomain .content__text .info__google {
  margin-top: 24px;

  padding: 0.56vw 0;
  border-top: 1px solid #53624e;
  border-bottom: 1px solid #53624e;
  text-transform: uppercase;
}
.content__text .info {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  transition: all 1.2s cubic-bezier(0.55, 0, 0.1, 1) 0.6s;
  font-size: 1.25vw;
  line-height: 1.67vw;
}
.content__text .info__links .sep {
  margin: 0 0.42vw;
  width: 0.21vw;
  height: 0.21vw;
  background-color: #53624e;
  border-radius: 100%;
}
.content__text .info__google {
  margin-top: 24px;
  font-family: "Morion Regular";
  font-weight: 400;
  padding: 0.56vw 0;
  border-top: 1px solid #53624e;
  border-bottom: 1px solid #53624e;
  text-transform: uppercase;
}
.content__text {
  width: 100%;
  text-align: center;
  transition: opacity 1.2s cubic-bezier(0.55, 0, 0.1, 1),
    transform 0.9s cubic-bezier(0.55, 0, 0.1, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3.19vw 1.11vw;
  font-family: "Noto Serif", serif;
  font-weight: 400;
}
.content__text:before {
  content: "";
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1);
  background: #53624e;
  height: 1px;
  width: 100%;
  transition: all 1.2s cubic-bezier(0.55, 0, 0.1, 1) 0.6s;
}
.module-homeclaim .content__text:before {
  border-bottom: 0;
  display: none;
}

.content__form {
  font-family: MillerText;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-top: 64px;
  margin: 0 auto;
}

.npt {
  width: 100%;
  position: relative;
  font-size: 1.39vw;
  line-height: 1.94vw;
  border-bottom: 1px solid #53624e;
}
.npt input {
  font-size: 1.39vw;
  line-height: 1.94vw;
  width: 100%;
  padding: 0 5.56vw 0.83vw 0;
  user-select: initial;
}
.npt__label {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  font-size: 1.39vw;
  line-height: 1.94vw;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.npt__error {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.39vw;
  line-height: 1.94vw;
  color: #e82828;
  pointer-events: none;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.content__form .npt + .npt {
  margin-top: 3.33vw;
}

.npt input {
  font-size: 1.39vw;
  line-height: 1.94vw;
  width: 100%;
  padding: 0 5.56vw 0.83vw 0;
  user-select: initial;
}

button,
input,
select,
textarea {
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: transparent;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.content__form .checks {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 48px;
}
.btnborder.btnSend .success {
  width: 1.39vw;
  height: 1.39vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition: all 0.45s ease;
}
.content__form {
  font-family: MillerText;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1100px;
  width: 100%;
  margin-top: 64px;
}
.npt.error .npt__label {
  transform: translateX(-100%);
  left: 100%;
  white-space: nowrap;
  opacity: 0.6;
}

.npt.error .npt__error {
  opacity: 1;
}

.npt.filled .npt__label {
  transform: translateX(-100%);
  left: 100%;
  opacity: 0.6;
}
.npt textarea {
  font-size: 1.39vw;
  line-height: 1.94vw;
  width: 100%;
  padding: 0 6.94vw 0.83vw 0;
  user-select: initial;
  resize: none;
  height: 7.36vw;
  max-height: 206.1px;
  min-height: 106px;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
/*  */
.content__form .btnSend {
  min-width: 15.28vw;
}
.btnborder.btnSend .success {
  width: 1.39vw vw;
  height: 1.39vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition: all 0.45s ease;
}
